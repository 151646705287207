* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  .menu_overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    overflow: hidden;
    color: #fff;
    z-index: 100;
    transform: translateY(-100%);
    transition: transform 0.5s ease;
    background: #ffe259;
    background: -webkit-linear-gradient(to right, #ffa751, #fb923c);
    background: linear-gradient(to right, #ffa751, #fb923c);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .list {
    height: 40%;
    width: 40%;
    position: relative;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .list li {
    height: 100px;
    padding: 20px;
  }
  
  .list a {
    white-space: nowrap;
    font-size: 2rem;
    text-decoration: none;
    color: #fff;
    display: block;
    border-bottom: 2px solid transparent;
    transition: all 0.4s ease;
  }
  
  .list a:hover {
    border-bottom: 2px solid #121212;
    color: #121212;
  }
  
  .menu_overlay .fas {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 2.1rem;
    transition: all 0.3s ease;
    z-index: 100;
  }
  .menu_overlay .fas:hover {
    color: #121212;
    transform: scale(1.1) rotate(180deg);
  }
  
  #menuBtn {
    display: none;
  }
  
  .landing_page {
    max-height: 100vh;
    height: 100%;
    width: 100%;
    position: relative;
  }
  
  .menu {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  
  .fas {
    font-size: 1.6rem;
    color: #FAF9F6;
  }
  
  .fas:hover {
    color: #faf9f69d;
  }
  
  .side_text {
    height: 10vh;
    width: 30%;
    color: #c94b4b;
    position: absolute;
    left: 0px;
    bottom: 0px;
    text-shadow: 0 0 1px #111;
    padding: 10px;
    font-size: 16px;
    color: #4b134f;
  }
  
  .main_text {
    color: #c94b4b;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 0 0 1px #111;
    display: flex;
    flex-direction: column;
  }
  
  .h2-r,
  .h2-l {
    font-size: 6rem;
  }
  
  .h2-r {
    transform: rotate(270deg);
    margin-left: -100px;
    z-index: 10;
  }
  
  .h2-l {
    margin-left: 100px;
    background-color: #4b134f;
    padding: 20px;
    z-index: 0;
  }
  
  @media screen and (max-width: 650px) {
    .list a {
      font-size: 1.4rem;
    }
  }
  
  @media screen and (max-width: 450px) {
    .list {
      display: flex;
      flex-direction: column;
    }
  }
  