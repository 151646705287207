@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body{
    /* background-color: #121212; */
    background-image: url("../images/bg.jpg");
}

@tailwind base;
@tailwind components;
@tailwind utilities;