@media only screen and (max-width: 750px) {
    .main_bg {
        height: 100vh !important;
    }
}

.arrow {
    height: 10px;
    animation: swipe 1s infinite;

}

.rarrow {
    height: 10px;
    animation: swipe 1s infinite;
}

@keyframes swipe {
    0% {
        height: 10px;
    }
    50% {
        height: 12px;
    }
    100% {
        height: 10px;
    }
}

.desc_box {
    width: 350px;
    height: 500px;
    border-radius: 40px;
    border: 5px solid #FAF9F6;
    position: relative;
    padding: 30px;
    margin-top: 100px;
}

.desc_box:nth-child(2) {
    margin-top: 200px;
}

.db_bg {
    background-color: #FAF9F6;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    position: absolute;
    top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1279px) {
    .desc_box:nth-child(1) {
        margin-top: 100px;
    }
    .desc_box {
        margin: 0;
        transform: scale(0.8);
    }
    .desc_box:nth-child(2) {
        margin-top: 0;
    }
}